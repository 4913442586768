import server from '../request';

// 查询 医搭 列表
export const getSysConfigList = () => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/sysConfig/list');
};

// 查询工单类型列表
export const getFormTypeListByConfigId = (data) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessType/queryAll', data);
};

// 工单类型删除;
export const deleteFormTemplate = (id) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessType/delete', {id});
};

// 工单类型状态修改;
export const enabelFormTemplate = ({id, status}) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessType/updateStatus', {id, status});
};

// 工单类型名称;
export const updateTemplate = ({code, name}) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessType/updateName', {code, name});
};
// 查询 业务场景 列表(根据医搭ID)
export const getConfigBusinessType = customizeSystemConfigCode => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/busScenario/listById', { customizeSystemConfigCode });
};

// 根据场景类型查询模板
export const getTemplatesByType = (code) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessType/queryTypeByScenario', {code });
};

// 新增工单类型
export const saveNewFormTemplate = (data) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessType/save', data);
};

// 查询 流程节点 列表（根据业务场景ID）
export const queryProcessLayoutApi = data => server.post('/xiaozi-xmb/wireless/saas/customize/processStencil/listById', data);

// 工作流和布局查看详情
export const viewProcessApi = data => server.post('/xiaozi-xmb/wireless/saas/customize/process/view', data);

// 修改工作流和布局
export const updateProcessApi = data => server.post('/xiaozi-xmb/wireless/saas/customize/process/update', data);

// 修改工作流和布局
export const updateProcessApiV2 = data => server.post('/xiaozi-xmb/wireless/saas/customize/process/updateProcess', data);

// 根据流程节点查询布局
export const queryLayoutByNodeApi = data => server.post('/xiaozi-xmb/wireless/saas/customize/process/queryLayoutByNode', data);

export const queryFormProcessInfo = data => server.post('/xiaozi-xmb/wireless/saas/customize/common/nextNodeTaskAssignee', data);

// 查询业务组件列表
export const getComponentListByConfigId = customizeSystemConfigCode => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessComponent/queryAll', { customizeSystemConfigCode });
};
// 查询业务组件列表
export const getComponentListWithLaoutByConfigId = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessComponent/queryList', params);
};
// 删除
export const deleteComponent = (id) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessComponent/delete', {id});
};
// 新增业务组件
export const addComponent = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessComponent/save', params);
};
// 更新业务组件状态
export const updateComponentStatus = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessComponent/updateStatus', params);
};
// 更新业务组件名称
export const updateComponentName = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/businessComponent/updateName', params);
};

// 业务组件详情
export const getComponentDetail = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/process/viewComponent', params);
};

// 更新业务组件详情
export const updateComponent = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/process/updateComponent', params);
};

// 查询打印模板列表
export const getPrintTemplateList = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/print/queryAll', params);
};

// 打印模板详情
export const getPrintTemplateDetail = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/print/detail', params);
};

// 打印模板删除
export const deletePrintTemplate = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/print/delete', params);
};

// 新增打印类型模板类型
export const addPrintTemplate = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/print/save', params);
};

// 修改打印类型模板
export const editPrintTemplate = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/print/update', params);
};

// 修改打印类型模板名称
export const editPrintTemplateName = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/print/updateName', params);
};

// 打印
export const printFormTemplate = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/print/printData', params, {
    responseType: 'blob',
  },
  );
};

// 导出
export const exportFormTemplate = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/print/exportData', params, {
    responseType: 'blob',
  },
  );
};

// 预览
export const previewFormTemplate = (params) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/print/preview', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'blob',
  },
  );
};

